@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);

/* TODO: Fix button specificity */
.reduced-padding.reduced-padding[data-v-77861184] {
    padding-inline: var(--spacing-3);
    white-space: nowrap;
}


.map-base-root[data-v-fe3097f6] {
    height: 300px;
    position: relative;
}
.slot-hider[data-v-fe3097f6] {
    /* Slots will interact with the map directly, not render any elements. */
    display: none;
}


[data-looks-like] {
    --font-scale: 1;
    --font-size: 1em;
    font-family: var(--type-headline);
    font-size: calc(var(--font-size) * var(--font-scale));
    line-height: 1.2;
}
[data-looks-like][data-small] {
    --font-scale: 0.8;
}
[data-looks-like="h1"] {
    --font-size: 60px;
}
[data-looks-like="h2"] {
    --font-size: 36px;
}
[data-looks-like="h3"] {
    --font-size: 20px;
}


.column-root[data-v-63839cd6] {
    margin: calc(var(--gap) / 2);
    -ms-flex-preferred-size: calc(var(--column-width) - var(--gap));
        flex-basis: calc(var(--column-width) - var(--gap));
}


.row-root[data-v-5603f9fc] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* Use `margin` instead of `gap` so the columns can be easily set as percents.
    There might be a better way to do this. */
    margin: calc(var(--gap) / -2);
}


.page-layout[data-v-aa2f30ee] {
    max-width: 90rem;
    margin: 0 auto;
}
[data-v-aa2f30ee] .page-layout-inset {
    margin-inline: calc(1rem + 1vw);
}
[data-v-aa2f30ee] .page-layout-section {
    margin-block: calc(2rem + 2vw);
}
[data-v-aa2f30ee] .page-layout-stack > * {
    margin-block: 1rem;
}


hr[data-v-b732df8e] {
    border: 1px solid #0002;
    border-block-end-width: 0;
}
.permissions-content[data-v-b732df8e] {
    margin: calc(1rem + 0.5vw);
}
.permissions-description[data-v-b732df8e] {
    border: 1px solid #0002;
    border-radius: 1rem;
    -webkit-box-shadow: 0 0.7rem 1rem #0001;
            box-shadow: 0 0.7rem 1rem #0001;
}
.permissions-list[data-v-b732df8e] {
    list-style: none;
    margin: 0;
    padding: 0;
}
.permissions-list > li[data-v-b732df8e] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.permissions-list > li[data-v-b732df8e]:not(:last-child) {
    -webkit-margin-after: 1ch;
            margin-block-end: 1ch;
}
.permissions-list > li[data-v-b732df8e]::before {
    -ms-flex-item-align: baseline;
        align-self: baseline;
    aspect-ratio: 1;
    background: gray;
    border-radius: 50%;
    content: "?";
    -ms-flex-negative: 0;
        flex-shrink: 0;
    height: 2.7ch;
    line-height: 2.7ch;
    -webkit-margin-end: 1ch;
            margin-inline-end: 1ch;
    text-align: center;
}
.permissions-list > li[data-value="yes"][data-v-b732df8e]::before {
    background: #dfd;
    color: var(--color-success);
    content: "✓";
}
.permissions-list > li[data-value="no"][data-v-b732df8e]::before {
    background: #fdd;
    color: var(--color-danger);
    content: "×";
}


.toggleable-textinput-container {
    position: relative;
}
.text-input {
    display: block;
    min-height: 1em;
    width: 100%;
}
.close-button {
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
}
.close-button:not(.toggleable-textinput-container:focus-within *) {
    display: none;
}


hr[data-v-c97fa7bc] {
    border: 1px solid #0002;
    border-block-end-width: 0;
}
[data-editing-this-section][data-v-c97fa7bc] {
    border: 3px dashed var(--color-primary);
}
.narrow[data-v-c97fa7bc] {
    margin-inline: auto;
    max-width: 50rem;
    text-align: center;
}
.side-by-side[data-v-c97fa7bc] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: calc(1rem + 2vw)
}
.side-by-side.reverse[data-v-c97fa7bc] {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
}
.side-by-side > *[data-v-c97fa7bc] {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 20rem;
            flex: 1 1 20rem;
}
.side-by-side > .image-block > img[data-v-c97fa7bc] {
    aspect-ratio: 1;
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
}
.byline-region[data-v-c97fa7bc] {
    background: #8882;
    border-radius: 0.7ch;
    display: inline-block;
    font-size: var(--type-small);
    font-weight: bold;
    -webkit-margin-end: 1ch;
            margin-inline-end: 1ch;
    padding: 0.1ch 0.8ch 0.1ch 0.4ch;
    white-space: nowrap;
}
.client-logo-container[data-v-c97fa7bc] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: bold;
    gap: 1rem;
}
.client-logo[data-v-c97fa7bc] {
    display: block;
    height: 6rem;
    min-width: 1px;
}
.intro-text[data-v-c97fa7bc] {
    color: var(--color-dark);
    font-size: var(--type-lead);
}
.map-container[data-v-c97fa7bc] {
    aspect-ratio: 16 / 9;
    max-height: 75vh;
    width: 100%;
}
.map[data-v-c97fa7bc] {
    border-radius: 0.8rem;
    height: 100%;
    width: 100%;
}
.collage[data-v-c97fa7bc] {
    display: grid;
    gap: 1vw;
    grid-auto-columns: minmax(0, 1fr);
}
.collage > *[data-v-c97fa7bc] {
    grid-column: span 2;
    border-radius: 0.4rem;
}
.collage[data-v-c97fa7bc]::before {
    content: "";
    grid-row: 1;
}
.collage[data-v-c97fa7bc] > :nth-child(2n-1) { grid-row: 2;
}
.collage[data-v-c97fa7bc] > :nth-child(2n) { align-self: flex-end; grid-row: 1;
}
.collage[data-v-c97fa7bc] > :nth-child(1) { aspect-ratio: 3 / 2;
}
.collage[data-v-c97fa7bc] > :nth-child(2) { aspect-ratio: 1 / 1;
}
.collage[data-v-c97fa7bc] > :nth-child(3) { aspect-ratio: 2 / 3;
}
.collage[data-v-c97fa7bc] > :nth-child(4) { aspect-ratio: 2 / 3;
}
.collage[data-v-c97fa7bc] > :nth-child(5) { aspect-ratio: 3 / 2;
}
.collage[data-v-c97fa7bc] > :nth-child(6) { aspect-ratio: 1 / 1;
}
.collage > :nth-child(5) ~ *[data-v-c97fa7bc] { display: none;
}
.bar-list[data-v-c97fa7bc],
.unstyled-list[data-v-c97fa7bc] {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* .bar-list > li {
    border-inline-start: 4px solid #8883;
    padding-inline-start: 1rem;
} */
:is(.bar-list, .unstyled-list) > li[data-v-c97fa7bc]:not(:last-child) {
    -webkit-margin-after: 1.5rem;
            margin-block-end: 1.5rem;
}
:is(.bar-list, .unstyled-list) > li[data-v-c97fa7bc] > :first-child {
    -webkit-margin-before: 0;
            margin-block-start: 0;
}
:is(.bar-list, .unstyled-list) > li[data-v-c97fa7bc] > :last-child {
    -webkit-margin-after: 0;
            margin-block-end: 0;
}
.region-links[data-v-c97fa7bc] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}
.region-links > li[data-v-c97fa7bc] {
    display: inline;
    padding-inline: 1ch;
    white-space: nowrap;
}
.dark-box[data-v-c97fa7bc] {
    background: var(--color-secondary);
    border-radius: 1rem;
    color: white;
    overflow: hidden;
}
.dark-box-content[data-v-c97fa7bc] {
    padding: 0 4rem;
}


.line-clamp[data-v-54bb7b0e] {
   /* https://css-tricks.com/almanac/properties/l/line-clamp/ */
  -webkit-line-clamp: var(--max-lines, 1);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.project-card-root[data-v-54bb7b0e] {
    background: var(--color-white);
    border: 1px solid #8888;
    border-radius: var(--spacing-2);
    -webkit-box-shadow: 0 5px 10px -10px black;
            box-shadow: 0 5px 10px -10px black;
    color: var(--color-black);
    text-decoration: inherit;
    display: block;
    padding: var(--spacing-4);
    -webkit-transition: border-color 150ms;
    transition: border-color 150ms;
}
.project-card-root[data-v-54bb7b0e]:hover {
    border-color: var(--color-primary);
}
.image[data-v-54bb7b0e] {
    background: #8888;
    border-radius: var(--spacing-1);
    display: block;
    height: 110px;
    -o-object-fit: cover;
       object-fit: cover;
    width: 110px;
}
.title[data-v-54bb7b0e] {
    font-family: var(--type-sans);
    font-size: var(--type-lead);
    color: var(--color-primary);
    margin: 0;
}
.regions[data-v-54bb7b0e] {
  --max-lines: 2;
}
.intro[data-v-54bb7b0e] {
  --max-lines: 4;
}
.intro[data-v-54bb7b0e] * {
    display: inline !important;
}
.intro[data-v-54bb7b0e] br {
    content: "";
}
.intro[data-v-54bb7b0e] br::after {
    content: " ";
}


.base-menu-root[data-v-6f18cdce],
.content-click-handler[data-v-6f18cdce],
[data-menu][data-v-6f18cdce] {
    display: contents;
}


.your-projects[data-v-fda9b3ce] {
    background: #8882;
    border-radius: var(--spacing-2);
    -webkit-box-shadow: 0 5px 10px -10px black inset;
            box-shadow: 0 5px 10px -10px black inset;
    margin: var(--spacing-8) 0;
    padding: var(--spacing-8);
}
.your-projects[data-v-fda9b3ce] > :first-child {
    -webkit-margin-before: 0;
            margin-block-start: 0;
}
.your-projects[data-v-fda9b3ce] > :last-child {
    -webkit-margin-after: 0;
            margin-block-end: 0;
}
.projects-list[data-v-fda9b3ce] {
    list-style: none;
    margin: var(--spacing-8) 0;
    padding: 0;
}
.projects-list[data-v-fda9b3ce] > :not(:first-child) {
    -webkit-margin-before: var(--spacing-8);
            margin-block-start: var(--spacing-8);
}
.projects-list[data-v-fda9b3ce] > :not(:last-child) {
    -webkit-margin-after: var(--spacing-8);
            margin-block-end: var(--spacing-8);
}


.content-container[data-v-145317a0] {
    margin-inline: auto;
    max-width: min(1344px, calc(1344 / 1440 * 100vw));
}
.section-heading[data-v-145317a0] {
    font-size: calc(var(--type-title) * 0.85);
}
.block-link[data-v-145317a0] {
    text-decoration: none;
}
.unstyled-list[data-v-145317a0] {
    list-style: none;
    padding: 0;
}
.sign-up-follower[data-v-145317a0] {
    margin: var(--spacing-4);
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    z-index: 1;
}
.sign-up-follower[data-offscreen][data-v-145317a0] {
    -webkit-transform: translateY(calc(var(--spacing-6) * -1)) translateY(-100%);
            transform: translateY(calc(var(--spacing-6) * -1)) translateY(-100%);
}
.landing-page-header[data-v-145317a0] {
    background: var(--color-secondary);
    -webkit-box-shadow: 0 0 10px black;
            box-shadow: 0 0 10px black;
    color: white;
    padding: var(--spacing-4);
    position: relative;
    text-align: center;
    z-index: 1;
}
.iseechange-logo[data-v-145317a0] {
    vertical-align: middle;
}
.header-joiner[data-v-145317a0] {
    display: inline-block;
    font-size: 40px;
    margin-inline: 1ch;
    vertical-align: middle;
}
[data-narrow] .header-joiner[data-v-145317a0] {
    font-size: 30px;
}
.organization-name[data-v-145317a0] {
    border-radius: 5px;
    display: inline-block;
    font-family: var(--type-headline);
    font-size: 40px;
    font-weight: bold;
    vertical-align: middle;
}
[data-narrow] .organization-name[data-v-145317a0] {
    font-size: 30px;
}
.hero[data-v-145317a0] {
    background: var(--color-secondary);
    color: white;
    padding: 5vh 0;
    position: relative;
}
.organization-logo-container[data-v-145317a0] {
    display: inline-block;
}
.organization-logo[data-v-145317a0] {
    background: white;
    border-radius: 10px;
    -webkit-box-shadow: 0 3px 10px -5px black;
            box-shadow: 0 3px 10px -5px black;
    height: 120px;
    -o-object-fit: contain;
       object-fit: contain;
    padding: 10px;
    vertical-align: top;
    min-width: 250px;
}
[data-should-mask] .organization-logo[data-v-145317a0] {
    opacity: 0;
}
.hero-cta-button[data-v-145317a0] {
    text-shadow: none;
}
.hero-background[data-v-145317a0] {
    height: 100%;
    left: 0;
    -o-object-fit: cover;
       object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
}
.hero-underlay[data-v-145317a0] {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #0006), to(#0000));
    background: linear-gradient(#0006 50%, #0000);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.hero-container[data-v-145317a0] {
    position: relative;
}
.hero-content[data-v-145317a0] {
    text-shadow: 0 2px 5px black;
}
.registration-form-wrapper[data-v-145317a0] {
    -webkit-filter: drop-shadow(0 10px 10px #0003);
            filter: drop-shadow(0 10px 10px #0003);
    margin-inline: auto;
    max-width: 60ch;
    scroll-margin-top: calc(var(--webnavigation--height) + var(--spacing-4));
}
.registration-form-wrapper[data-v-145317a0]:not([data-narrow]) {
    margin-bottom: calc(-5vh - 3em);
}
.registration-form-header[data-v-145317a0] {
    background: linear-gradient(135deg, #A2CD3D, #44ACAD);
    border-radius: 20px 20px 0 0;
    color: white;
    padding: var(--spacing-4) var(--spacing-8);
    text-align: center;
}
.registration-form-heading[data-v-145317a0] {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.7rem;
    letter-spacing: 0.05ch;
    margin: 0;
    text-transform: uppercase;
}
.registration-form-content[data-v-145317a0] {
    background: white;
    border-radius: 0 0 20px 20px;
    color: black;
    padding: var(--spacing-4) var(--spacing-8);
}
.girl-scouts[data-v-145317a0] {
    background: var(--color-light);
}
.girl-scouts-block[data-v-145317a0] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    text-align: center;
}
[data-smaller-icons] .girl-scouts-icon[data-v-145317a0] {
    height: auto;
    width: 80px;
}
.whitby-section[data-v-145317a0] {
    background: var(--color-light);
    font-size: calc(var(--type-lead));
}
.how-it-works[data-v-145317a0] {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(75%, #0000), to(#0001));
    background: linear-gradient(#0000 75%, #0001);
}
.how-it-works-icon-wrapper[data-v-145317a0] {
    position: relative;
}
.how-it-works-entry:not(:last-child) > .how-it-works-icon-wrapper[data-v-145317a0]:after {
    background: var(--color-primary);
    content: "";
    height: 120%; /* This isn't perfect, but it's probably flexible enough. */
    left: 50%;
    margin-left: -0.75rem;
    position: absolute;
    top: 50%;
    width: 1.5rem;
}
.how-it-works-icon[data-v-145317a0] {
    /* margin-block: var(--spacing-8); */
    position: relative;
    z-index: 1;
}
.how-it-works-explanation[data-v-145317a0] {
    font-size: calc(var(--type-subtitle) / (24 / 16))1;
    -webkit-margin-end: 1em;
            margin-inline-end: 1em;
}
.how-it-works-explanation[data-v-145317a0] h3 {
    color: var(--color-danger);
    font-size: var(--type-subtitle);
}
.screenshots-layout[data-v-145317a0] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.screenshots-layout[data-v-145317a0] > :first-child {
    margin: 4rem -4rem 0 0;
    position: relative;
    z-index: 1;
}
.screenshot[data-v-145317a0] {
    -webkit-mask-image: url(/img/campaigns/partners-shared/phone-mask.png);
            mask-image: url(/img/campaigns/partners-shared/phone-mask.png);
    -webkit-mask-size: cover;
            mask-size: cover;
    min-width: 0;
}
.investigation-image[data-v-145317a0] {
    aspect-ratio: 20 / 13;
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
}
.post-container[data-v-145317a0] {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #0008), to(#0000)), var(--post-image, #888) 50% 50% / cover var(--color-secondary);
    background: linear-gradient(#0008 50%, #0000), var(--post-image, #888) 50% 50% / cover var(--color-secondary);
    width: 100%;
}
.post-content[data-v-145317a0] {
    aspect-ratio: 1;
    color: white;
}
.media[data-v-145317a0] {
    background: #8882;
}
.sharing[data-v-145317a0] {
    background: linear-gradient(135deg, #A2CD3D55, #44ACAD55);
}
.icon-link[data-v-145317a0] {
    color: inherit;
    text-decoration: inherit;
}


/*# sourceMappingURL=organization.c6cef951.css.map*/